import request from '@/utils/request'
/**
 * 根据主键id查询字典表
 */
export function GetAllById (id) {
  return request({
    url: `/dev-api/getAllById?id=${id}`,
    method: 'get',
    params: id
  })
}
/**
 * 学员申请请假
 */
export function LeaAdd (leave) {
  return request({
    url: '/leave/pcleave',
    method: 'post',
    data: leave
  })
}
export function getClassNameByLogin (username) {
  return request({
    url: `/leave/getClassNameByLogin?username=${username}`,
    method: 'get'
  })
}
/**
 * 初始化个人请假信息
 */
 export function getLeaveList (query) {
  return request({
    url: '/leave/getLeaveList',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 初始化个人课程信息
 */
 export function getCourseList (query) {
  return request({
    url: '/beihaieducation/stuCourse/getCourses',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 获取个人课程信息
 */
 export function getCourses (query) {
  return request({
    url: '/beihaieducation/course/getCourses',
    method: 'get',
    params: {
      ...query
    }
  })
}
/**
 * 删除个人请假信息
 */
export function delLeave (leaveId) {
  return request({
    url: `/leave/delLeave?id=${leaveId}`,
    method: 'get'
  })
}
/**
 * 根据请假id查询请假信息
 */
export function getLeave (leaveId, username) {
  return request({
    url: `/leave/getLeave?id=${leaveId}&username=${username} `,
    method: 'get'
  })
}
