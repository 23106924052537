<template>
  <div class="boxLe">
    <el-row class="dash-board">
      <el-button type="primary" v-permission="['Leave:select']" class="inquire" @click="attendSel()">查询</el-button>
      <el-button type="warning" @click="reset" class="reset">重置</el-button>
      <el-button type="primary" class="authenticates" @click="authenticates">批量审批</el-button>
      <p class="name" style="font-size: 12px">姓名:</p>
      <p class="one" style="font-size: 12px">状态:</p>
      <p class="time" style="font-size: 12px">提交时间:</p>
      <p class="iocpn" style="font-size: 12px">班级:</p>
      <p class="two" style="font-size: 12px">类别:</p>
      <el-input v-model="stuName" class="in1" placeholder="输入姓名"></el-input><!--姓名-->
      <el-select @change="selectGet" v-model="className" class="in3">
        <el-option v-for="(i,index) in dataList2" :key="index" :label="i.className" :value="i.classId"></el-option>
      </el-select>
      <el-select v-model="leaveType" class="in4"><!--类别-->
        <div v-for="(i,k) in attStatusList" :key="k">
          <el-option :value="i.id" :label="i.value"></el-option>
        </div>
      </el-select>
      <!--状态-->
      <el-select class="in2" v-model="leaveStatus">
        <el-option :value="2" label="审批中"></el-option>
        <el-option :value="3" label="审批成功"></el-option>
        <el-option :value="4" label="审批不通过"></el-option>
      </el-select>
      <div class="data"><!--日期-->
        <el-date-picker v-model="quitTime" type="date" placeholder="选择日期" style="width: 150px">
        </el-date-picker>
      </div>
    </el-row>
    <el-row class="bg">
    <el-table
      :data="dataList"
      ref="multipleTable"
      style="width: 100%;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" v-model="multipleSelection" :selectable="checkSelect">
      </el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        width="80">
        <template slot-scope="scope">
          <span>{{scope.row.stuName}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="请假类别"
        align="center"
        width="68">
        <template slot-scope="scope">
          <span v-if="scope.row.leaveType==2"> 病假 </span>
          <span v-if="scope.row.leaveType==5"> 事假 </span>
        </template>
      </el-table-column>
      <el-table-column
        label="班级"
        align="center"
        width="">
        <template slot-scope="scope">
          <span>{{scope.row.className}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="课程内容"
        align="center"
        width="">
        <template slot-scope="scope">
          <span>{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="请假时间"
        align="center"
        width="146">
        <template slot-scope="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="请假事由"
                       align="center"
                       width="140">
        <template slot-scope="scope">
          <span>{{scope.row.leaveReason}}</span>
        </template>
      </el-table-column>
      <el-table-column label="请假图片" align="center" width="200">
          <template slot-scope="scope">
          <span class="spani" v-if="scope.row.url">
            <el-popover placement="right" trigger="click">
              <!--trigger属性值：hover、click、focus 和 manual-->
              <img :src="scope.row.url" height="400">
              <img slot="reference" :src="scope.row.url" height="50" width="50">
            </el-popover>
          </span>
        </template>
        </el-table-column>
      <el-table-column
        label="提交时间"
        align="center"
        width="84">
        <template slot-scope="scope">
          <span>{{scope.row.submitTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
        width="100">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.status == 12">班主任审批中</el-tag>
          <el-tag type="primary" v-if="scope.row.status == 11">审批中</el-tag>
          <el-tag type="primary" v-if="scope.row.status == 21">组织部审批中</el-tag>
          <el-tag type="primary" v-if="scope.row.status == 22">审批中</el-tag>
          <el-tag type="success" v-if="scope.row.status == 3">审批成功</el-tag>
          <el-tag type="danger" v-if="scope.row.status == 4">审批不通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="280">
        <template slot-scope="scope">
          <el-button size="mini" v-permission="['Leave:headmaster']" @click="open(scope.row)" type="primary" v-if="scope.row.status === 11">审核</el-button>
          <el-button size="mini" v-permission="['Leave:organization']" v-if="scope.row.status == 22" @click="openerd(scope.row)" type="primary">组织部审批</el-button>
          <el-button
            type="danger"
            size="mini"
            @click="delLeave(scope.row)">删除
          </el-button>
          <!-- <el-button size="mini" v-permission="['Leave:headmaster']" @click="openUrl(scope.row)" type="warning" v-if="scope.row.url">下载附件</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <!-- 批量审批 -->
    <el-dialog title="班主任审批" :visible.sync="toponce">
    <el-input type="textarea" rows="5" style="width: 500px" v-model="remark" placeholder="请输入审批意见" :maxlength="20"></el-input>
    <div style="margin-top:10px">
      <el-button type="warning" @click="noAuther(3)">不通过</el-button>
      <el-button type="primary" @click="noAuther(2)">通过</el-button>
    </div>
  </el-dialog>
    <el-dialog title="组织部审批" :visible.sync="nicetwo">
      <el-input type="textarea" rows="5" style="width: 500px" v-model="remark" placeholder="请输入审批意见" :maxlength="20"></el-input>
      <div style="margin-top:10px">
        <el-button type="warning" @click="noAuthers(3)">不通过</el-button>
        <el-button type="primary" @click="noAuthers(2)">确定</el-button>
      </div>
    </el-dialog>
    <!-- 班主任批量审批后弹窗 -->
    <el-dialog title="是否需要组织部审批" :visible.sync="nicethree" :show-close="showclose" :close-on-click-modal="showclose"
      width="30%">
      <div style="margin-top:10px">
        <el-button type="warning" @click="unwanted">不需要</el-button>
        <el-button type="primary" @click="need">需要</el-button>
      </div>
    </el-dialog>
    <!-- 管理员审批 -->
    <el-dialog title="组织部审批" :visible.sync="zhuzhibu">
      <el-form
          :model="zzB"
          label-position="right"
          label-width="120px"
          :rules="zzBrules"
          ref="zzB">
          <el-col>
             <el-form-item label="请假原因" style="text-align: left;">
                {{leves.leaveReason}}
             </el-form-item>
            <el-form-item label="班主任审批意见" style="text-align: left;">
              <span v-if="leves.approverHeadmaster">{{leves.approverHeadmaster}}</span>
              <span v-else>暂无评语</span>
             </el-form-item>
             <el-form-item label="审批意见" prop="approverZzb">
                <el-input type="textarea" :rows="5" resize="none" placeholder="输入管理员审批意见" v-model="zzB.approverZzb" :maxlength="20"></el-input>
             </el-form-item>
          </el-col>
          <el-button @click="not('zzB')">不通过</el-button>
          <el-button type="primary" @click="AUTH('zzB')">通过</el-button>
      </el-form>
    </el-dialog>
    <!-- 班主任审批 -->
    <el-dialog title="班主任审批" :visible.sync="handleEdit">
       <el-form
          :model="leves"
          label-position="right"
          label-width="120px"
          :rules="levesrules"
          ref="leves">
          <el-form-item label="请假原因" prop="leaveReason" style="text-align: left;">
            <span>{{leves.leaveReason}}</span>
          </el-form-item>
          <el-form-item label="审批意见" prop="approverHeadmaster">
            <el-input type="textarea" :rows="5" resize="none" placeholder="请输入班主任审批意见" v-model="leves.approverHeadmaster" :maxlength="20"></el-input>
          </el-form-item>
        <el-button @click="transit('leves')">不通过</el-button>
        <el-button type="primary" @click="auth('leves')">通过</el-button>
      </el-form>
    </el-dialog>
    <!-- 班主任审批后弹窗 -->
    <el-dialog title="是否需要组织部审批" :visible.sync="nicefour" :show-close="showclose" :close-on-click-modal="showclose"
      width="30%">
      <div style="margin-top:10px">
        <el-button type="warning" @click="unwantedtwo">不需要</el-button>
        <el-button type="primary" @click="needtwo">需要</el-button>
      </div>
    </el-dialog>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { auth, upLeaveZzb, getAllleaveList } from '@/api/training.js'
  import { delLeave } from '@/api/leave.js'
  import { getAllClassByMoHu } from '@/api/user.js'
  import { leaveBatch, leaveBatchAdmin, isZZBAudit } from '@/api/admin.js'
  import { getCookie } from '@/utils/base'
  export default {
    name: 'Leavefer',
    data () {
      return {
        zzB: {
          leaveId: '',
          approverZzb: ''
        },
        openster: false,
        zhuzhibu: false,
        attendanceList: [
          { id: 1, value: '待审批' },
          { id: 2, value: '通过' },
          { id: 3, value: '未通过' }
        ],
        attStatusList: [
          { id: 2, value: '病假' },
          { id: 5, value: '事假' }
        ],
        leves: {
          leaveId: '',
          leaveReason: '',
          approverHeadmaster: ''
        },
        leaveId: '',
        leaveType: '',
        leaveStatus: '',
        stuName: '',
        className: '',
        quitTime: '',
        dataList: [
          {
            leaveId: '',
            stuName: '',
            leaveStatus: '',
            className: '',
            createTime: '',
            submitTime: '',
            leaveReason: '',
            leaveType: ''
          }
        ],
        dataList2: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        titleee: '审批语',
        titlee: '审批',
        handleEdit: false,
        remark: '',
        formLabelWidth: '100px',
        zzBrules: {
          // approverZzb: [{ required: true, message: '请填写审批意见', trigger: 'blur' }]
        },
        levesrules: {
          // approverHeadmaster: [{ required: true, message: '请填写班主任名字', trigger: 'blur' }]
        },
        multipleSelection: [],
        toponce: false,
        nicetwo: false,
        nicethree: false,
        needlist: [],
        showclose: false,
        nicefour: false
      }
    },
    methods: {
      selectGet(index) {
        this.classFrom.classId = this.dataList[index].classId
        this.classFrom.className = this.dataList[index].className
      },
      GetAllById (id) { // 字典
      },
      attendSel() {
        const query = {
          stuName: this.stuName,
          status: this.leaveStatus,
          classId: this.className,
          type: this.leaveType,
          submitTime: this.quitTime,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getAllleaveList(query).then(res => {
          if (res.data.code === '200') {
            this.dataList = res.data.data.rows
            this.total = res.data.data.total // 查询总条数
          }
        })
      },
      getAllClassByMoHu: function () {
        getAllClassByMoHu(this.listQuery).then(resp => {
          if (resp.data.code === '0') {
            this.dataList2 = resp.data.data.rows // 接收返回的数据
          }
        })
      },
      // 管理员审批不通过
      not(zzB) {
         this.$refs[zzB].validate(valid => {
           if (valid) {
            const zzB = {
              approverZzb: this.zzB.approverZzb,
              id: this.leaveId,
              leaveStatusZzb: this.status = 3
            }
            upLeaveZzb(zzB).then(resp => {
              if (resp.data.code === '200') {
                  this.zhuzhibu = false
                  this.$notify({
                    title: '审批成功',
                    type: 'success'
                  })
                  this.attendSel()
                } else {
                  this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
          }
        })
      },
      openerd(row) {
        this.zhuzhibu = true
        this.zzB.approverZzb = ''
        this.leves = row
        this.leaveId = row.leaveId
        // this.zhuzhibu = true
        // this.zzB.approverZzb = ''
        // this.leaveId = id
        // this.dataList.find(item => {
        //   item.leaveId = this.leaveId
        //   this.leves.leaveReason = item.leaveReason
        //   this.leves.approverHeadmaster = item.approverHeadmaster
        // })
      },
      open (row) {
        this.handleEdit = true
        this.leves.approverHeadmaster = ''
        console.log(row)
        this.leves = row
         this.leaveId = row.leaveId
        //  this.leaveId = id
          // this.dataList.find(item => {
          //   item.leaveId = this.leaveId
          //   this.leves.leaveReason = item.leaveReason
          //   this.leves.approverHeadmaster = item.approverHeadmaster
          // })
      },
      // 查看附件
      openUrl(row) {
        console.log(row.url)
        window.open(row.url)
      },
      // 管理员审批通过
      AUTH(zzB) {
        this.$refs[zzB].validate(valid => {
           if (valid) {
            const zzB = {
              approverZzb: this.zzB.approverZzb,
              id: this.leaveId,
              leaveStatusZzb: this.status = 2
            }
            upLeaveZzb(zzB).then(resp => {
              this[zzB] = {}
              if (resp.data.code === '200') {
                this.zhuzhibu = false
                this.$notify({
                  title: '审批成功',
                  type: 'success'
                })
                this.attendSel()
              } else {
                  this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
           }
        })
      },
       // 班主任审批通过
      auth (leves) {
        this.$refs[leves].validate(valid => {
           if (valid) {
              const leves = {
                // leaveStatusHead: this.status = 2,
                leaveStatusHead: 2,
                id: this.leaveId,
                approverHeadmaster: this.leves.approverHeadmaster
              }
              this.status = 2
              auth(leves).then(resp => {
                if (resp.data.code === '0') {
                  // this.handleEdit = false
                  this.$notify({
                    title: '审批成功',
                    type: 'success'
                  })
                  this.nicefour = true
                  // this.attendSel()
                } else {
                    this.$notify.error({
                    message: resp.data.msg
                  })
                }
              })
            }
        })
      },
       // 班主任审批不通过
      transit (leves) {
        this.$refs[leves].validate(valid => {
           if (valid) {
            const leves = {
              // leaveStatusHead: this.status = 3,
              leaveStatusHead: 3,
              id: this.leaveId,
              approverHeadmaster: this.leves.approverHeadmaster
            }
            auth(leves).then(resp => {
              if (resp.data.code === '0') {
                this.handleEdit = false
                this.$notify({
                  title: '审批成功',
                  type: 'success'
                })
                this.attendSel()
              } else {
                  this.$notify.error({
                  message: resp.data.msg
                })
              }
            })
          }
        })
      },
      reset() { // 重置
        this.leaveType = ''
        this.leaveStatus = ''
        this.stuName = ''
        this.className = ''
        this.quitTime = ''
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.attendSel()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.attendSel()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      // 请假删除
      delLeave(row) {
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            delLeave(row.leaveId).then(res => {
              if (res.data.code === '0') {
                this.$notify({
                  title: '删除成功',
                  type: 'success'
                })
                // history.go(0) // 刷新当前页面
                this.attendSel()
                const index = this.dataList.indexOf(row)
                this.dataList.splice(index, 1)
                this.total--
              }
            })
          }
        }).catch(() => {
        })
      },
       // 批量审批
      authenticates() {
        if (this.multipleSelection.length > 0) {
           this.multipleSelection.forEach(item => {
            this.needlist.push(item.leaveId)
          })
          console.log(this.needlist)
          if (getCookie('admin') === 'true') {
            this.nicetwo = true
          } else {
            this.toponce = true
          }
        } else {
          this.$message.error('选择为空，请勾选需要批量审批的数据！')
        }
      },
      // 批量审批--班主任审批
      noAuther(type) {
        var list = []
        this.multipleSelection.forEach(item => {
           list.push({ id: item.leaveId })
        })
        const query = {
             leaveStatusHead: type, // 2通过 3不通过
             leaves: list,
             approverHeadmaster: this.remark
        }
        leaveBatch(query).then(resp => {
          if (resp.data.code === '0') {
            // this.toponce = false
            this.remark = ''
             this.$notify({
              title: '审批成功',
              type: 'success'
            })
            this.nicethree = true
            // this.attendSel()
          } else {
            this.$notify.error({
              message: resp.data.msg
            })
          }
        })
      },
      // 需要组织部审批
      need() {
          isZZBAudit(this.needlist).then(resp => {
            if (resp.data.code === '200') {
              this.$notify({
                title: '成功',
                type: 'success'
              })
              this.nicethree = false
              this.toponce = false
              this.attendSel()
            } else {
              this.$notify.error({
                message: resp.data.msg
              })
            }
          })
      },
      needtwo() {
          isZZBAudit(this.leaveId).then(resp => {
            if (resp.data.code === '200') {
              this.$notify({
                title: '成功',
                type: 'success'
              })
              this.nicefour = false
              this.handleEdit = false
              this.attendSel()
            } else {
              this.$notify.error({
                message: resp.data.msg
              })
            }
          })
      },
      // 不需要组织部审批
      unwanted() {
         this.nicethree = false
         this.toponce = false
         this.attendSel()
      },
      unwantedtwo() {
         this.nicefour = false
         this.handleEdit = false
         this.attendSel()
      },
      // 批量审批--管理员审批
      noAuthers(type) {
        var list = []
        this.multipleSelection.forEach(item => {
          console.log(item)
           list.push({ id: item.leaveId })
        })
        const query = {
             leaveStatusZzb: type, // 2通过 3不通过
             leaves: list,
             approverZzb: this.remark
        }
        console.log(query)
        leaveBatchAdmin(query).then(resp => {
          if (resp.data.code === '0') {
            this.nicetwo = false
            this.remark = ''
            this.$notify({
              title: '审批成功',
              type: 'success'
            })
            this.attendSel()
          } else {
              this.$notify.error({
              message: resp.data.msg
            })
          }
        })
      },
      checkSelect (row) {
        let isChecked = true
        if (row.status === 11) {
          isChecked = true
        } else if (row.type === '2' && row.status === 22) {
          isChecked = true
        } else {
          isChecked = false
        }
        return isChecked
      }
    },
    mounted () {
      // this.GetAllById(180) // 请假状态
      // this.GetAllById(184) // 请假类别
      this.attendSel()
      this.getAllClassByMoHu()// 初始化班级
    }
  }
</script>

<style scoped>
  .boxLe{margin-top: -10px}
  .dash-board {
    display: flex;
    /*left: 10px;*/
    background-color: white;
    height: 120px;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 70px;
    left: 500px;
  }
  .reset{
    position: absolute;
    top: 70px;
    left: 560px;
  }
    .authenticates{
    position: absolute;
    top: 70px;
    left: 640px;
  }
  .in1{
    width: 150px;
    position: absolute;
    top: 20px;
    left: 70px;
  }
  .in2{
    width: 150px;
    position: absolute;
    top: 20px;
    left: 300px;
  }
  .in3{
    width: 150px;
    position: absolute;
    top: 70px;
    left: 70px;
  }
  .in4{
    width: 150px;
    position: absolute;
    top: 70px;
    left: 300px;
  }
  .name{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 20px;
  }
  .one{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 260px;
  }
  .iocpn{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 20px;
  }
  .two{
    font-size: 15px;
    position: absolute;
    top: 65px;
    left: 260px;
  }
  .time{
    font-size: 15px;
    position: absolute;
    top: 15px;
    left: 495px;
  }
  .data{
    position: absolute;
    top: 20px;
    left: 560px;
  }
</style>
